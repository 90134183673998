import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  homePage: {
    justifyContent: "center",
    backgroundColor: "black",
    display: "flex",
  },
  loginPage: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    // border: "2px solid red",
    padding: 15,
    width:"100%",
    overflow: "hidden",
  },
  loginLogo: {
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid red",
  },
  imgDiv: {
    "@media(max-width: 950px)": {
      display: "none",
    },
  },
  title: {
    width: "100%",
    height: "100%",
  },
  loginFormOuter: {
    // border: "1px solid blue",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    // maxHeight: "67vh",
    overflow: "auto",
    position: "relative",
    "&::-Webkit-Scrollbar": { display: null, width: 0, height: 0 },
    "&:-WebkitScrollbar": { display: null, width: 0, height: 0 },
    // backgroundColor:"pink",
  },
  loginFormOuterSignup: {
    // border: "1px solid blue",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    overflow: "auto",
    position: "relative",
    "&::-Webkit-Scrollbar": { display: null, width: 0, height: 0 },
    "&:-WebkitScrollbar": { display: null, width: 0, height: 0 },
    // backgroundColor:"pink",
  },
  loginForm: {
    width: "auto",
    height: "auto",
    border: "1px solid blue",
    textAlign: "left",
    padding: 20,
    paddingBottom: 10,
  },
  input: {
    display: "block",
    width: " 97%",
    height: "34px",
    fontSize: "14px",
    color: " #5555",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  nameTitle: {
    color: "#89867e",
    maxWidth: "100%",
    fontWeight: "bold",
  },
  checkBox: {
    width: "13%",
    height: "22px",
  },
  linkname: {
    display: "flex",
    alignItems: "center",
    color: " #555",
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "20px",
  },
  linkBtn: {
    color: "#888",
    fontSize: "17px",
  },
  buttonBox: {
    // border: "1px solid red",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  button: {
    backgroundColor: "#fdca72",
    color: "#ffff",
    justifyContent: "space-evenly",
    alignItems: "center",
    display: "flex",
    borderRadius: 5,
    border: "none",
    width: "70%",
    height: "80px",
  },

  loginBtn: {
    marginRight: 40,
    width: "65%",
    fontSize: "20px",
    fontWeight: "bold",
  },
  forgetLink: {
    textDecoration: "none",
    color: "#888",
    fontSize: "17px",
  },
  dropDownDiv: {
    position: "absolute",
    zIndex: 999,
    width: 220,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexFlow: "column",
    height: 100,
    // marginLeft:1,
    overflow: "auto",
    backgroundColor: "#ffff",
    // border: "1px solid gray",
    elivation:5,
    borderRadius:"0px 10px 10px 10px",
    elivation: 10,
    "&::-Webkit-Scrollbar": { display: null, width: 0, height: 0 },
    "&:-WebkitScrollbar": { display: null, width: 0, height: 0 },

  },
}));

export default useStyles;
