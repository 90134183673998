import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import useStyles from "./styles";
import { json, Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Button from "@mui/material/Button";
import _ from "lodash";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LoginTwoToneIcon from "@mui/icons-material/LoginTwoTone";
import { toast } from "react-toastify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Modal from "@mui/material/Modal";
import { notificationStaticArray } from "../../Config/Static_Data";
// import usePushNotifications from "../../usePushNotifications";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";


const CHeader = (props) => {

  // const {
  //   userConsent,
  //   pushNotificationSupported,
  //   onClickAskUserPermission,
  //   error,
  //   loading
  // } = usePushNotifications();

  // const isConsentGranted = userConsent === "granted";

  const { name = "" } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const theme = useTheme();
  const width = window.innerWidth;
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const {notificationData } = useSelector((state) => state.auth);
  const {setNotificationData } = authActions;
  const isTablet = (window.innerWidth = 768);
  const [showMenu, setShowMenu] = useState(false);
  const [headerNotiArray, setHeaderNotiArray] = useState([]);
  const [finalNotification, setFinalNotification] = useState([]);
  const notificationArray = JSON.parse(
    localStorage.getItem("notificationLocalStoreArray")
  );
  const arrNum = _.isArray(finalNotification) && !_.isEmpty(finalNotification) ? finalNotification.length : 0;

  React.useEffect(() => {
    setHeaderNotiArray(notificationArray);
    setFinalNotification(JSON.parse(localStorage.getItem("notificationData")));
  }, []);

  const handleDrawerOpen = () => {
    setShowMenu(true);
  };
  const closeModal = () => {
    setShowMenu(false);
  };
  const drawerWidth = "60vw";
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const [showMenu1, setShowMenu1] = useState(false);

  const id = open ? "simple-popover" : undefined;

  const checkRead = (item) => {
    let dummArray = notificationData;
    dummArray.map((item2, ind2) => {
      if (item2.id === item?.id) {
        item2.isRead = true;
      }
    });
    localStorage.setItem(
      "notificationLocalStoreArray",
      JSON.stringify(dummArray)
    );

    dispatch(setNotificationData(dummArray));
  };

  return (
    <Grid container lg={12} className={styles.homePage}>
      <Grid container lg={12} className={styles.homeDiv}>
        <Grid className={styles.gridOne} lg={4} md={5} sm={7} xs={10}>
          <Link
            to="/"
            style={{pointerEvents: !_.isEmpty(name) ? "" : "none" }}
          >
            <img
              src="https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png"
              className={styles.img}
            />
          </Link>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              pointerEvents: !_.isEmpty(name) ? "" : "none",
            }}
          >
            <h1 className={styles.title}>Beyond Infinity</h1>
          </Link>
        </Grid>
        <Grid className={styles.gridTwo} lg={8} md={7} sm={5}>
          <li className={styles.listScreen}>
            {!_.isEmpty(name) ? (
              <label style={{ color: "#ffff" }}>
                {" "}
                <b style={{ color: "#4c19c9" }}>
                  <i>Welcome...</i> {name}
                </b>
              </label>
            ) : null}
          </li>
          {/* <li>
            <button disabled={!pushNotificationSupported || isConsentGranted} onClick={onClickAskUserPermission}>
        {isConsentGranted ? "Consent granted" : " Ask user permission"}
      </button>
            </li> */}
            <>
              {_.isEmpty(name) ? null : (
                <>
                  <li className={styles.listScreen}>
                    <Link
                      to="/"
                      className={styles.screen}
                      onClick={() => {
                        setShowMenu1(false);
                      }}
                    >
                      {/* <HomeRoundedIcon fontSize="small" /> */}
                      Home
                    </Link>
                  </li>
                  <p style={{ color: "#ffff" }}>|</p>
                </>
              )}
            </>

          {!_.isEmpty(name) ? (
            <>
              <li className={styles.listScreen}>
                <Link
                  to="Logout"
                  className={styles.screen}
                  onClick={() => {
                    setShowMenu1(false);
                  }}
                >
                  {/* <LogoutRoundedIcon  fontSize="small"/> */}
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <li className={styles.listScreen}>
              <Link
                to="Login"
                className={styles.screen}
                onClick={() => {
                  setShowMenu1(false);
                }}
              >
                Login
              </Link>
            </li>
          )}
          {!_.isEmpty(name) ? (
            <>
              <p style={{ color: "#ffff" }}>|</p>
              <li
                className={styles.listScreen}
                onClick={() => {
                  setAnchorEl(true);
                  setShowMenu1(!showMenu1);
                  handleOpen();
                }}
              >
                <Link
                  to="/"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className={styles.number}>{arrNum}</span>
                  <NotificationsNoneIcon
                    fontSize="large"
                    className={styles.notification}
                  />
                </Link>
              </li>
            </>
          ) : null}
        </Grid>
        <Grid lg={7} md={7} sm={5} xs={2} className={styles.gridThree}>
          <MenuIcon fontSize="large" onClick={handleDrawerOpen} />
        </Grid>
        <Drawer
          sx={{
            width: "100vw",
            // height: "100vh",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              height: "auto",
              backgroundColor: null,
              borderLeft: 0,
            },
          }}
          variant="persistent"
          anchor="right"
          open={showMenu}
          onClose={() => {
            setShowMenu(false);
          }}
        >
          <div className={styles.drawerSabMenu}>
            <Grid
              container
              style={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                // border: "2px solid red",
              }}
              gap={2}
            >
              {_.isEmpty(name) ? null : (
                <li className={styles.listScreenMenu}>
                  <Link
                    to="/"
                    className={styles.screen}
                    onClick={() => {
                      closeModal();
                      setShowMenu1(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HomeRoundedIcon
                      fontSize="small"
                      className={styles.iconRightSpace}
                    />
                    Home
                  </Link>
                </li>
              )}
              {!_.isEmpty(name) ? null : (
                <li className={styles.listScreenMenu}>
                  <Link
                    to="Login"
                    className={styles.screen}
                    onClick={() => {
                      setShowMenu1(false);
                      closeModal();
                    }}
                  >
                    <LoginTwoToneIcon
                      fontSize="small"
                      className={styles.iconRightSpace}
                    />
                    Login
                  </Link>
                </li>
              )}

              {!_.isEmpty(name) ? (
                <li
                  className={styles.nListScreen}
                  onClick={() => {
                    setAnchorEl(true);
                    closeModal();
                    setShowMenu1(!showMenu1);
                    // setOpen(true);
                  }}
                >
                  {/* <span className={styles.drowerNum}>15</span> */}
                  <Link
                    to="/Notification"
                    className={styles.screen}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <NotificationsNoneIcon
                      style={{
                        color: "#fa6efc",
                        paddingLeft: 5,
                        fontSize: 25,
                        paddingTop: 5,
                      }}
                      className={styles.iconRightSpace}
                    />
                    Notification{" "}
                    <span
                      style={{
                        // border: "1px solid red",
                        padding: 5,
                        backgroundColor: "#ffff",
                        borderRadius: 5,
                        marginLeft: 5,
                        fontSize: 10,
                      }}
                    >
                      {arrNum}
                    </span>
                  </Link>
                </li>
              ) : null}

              {!_.isEmpty(name) ? (
                <>
                  <li className={styles.listScreenMenu}>
                    <Link
                      to="Logout"
                      className={styles.screen}
                      onClick={() => {
                        closeModal();
                        setShowMenu1(false);
                        // localStorage.setItem("pwaUserDetails", JSON.stringify({}));
                        // refreshPage();
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LogoutRoundedIcon
                        fontSize="small"
                        className={styles.iconRightSpace}
                      />
                      Logout
                    </Link>
                  </li>
                </>
              ) : null}
            </Grid>
            <CloseIcon
              onClick={closeModal}
              style={{
                // color: "rgb(76 25 201)",
                color: "#ffff",
                paddingRight: 10,
                fontSize: 30,
                paddingTop: 10,
              }}
            />
          </div>
        </Drawer>
        <Modal
        open={open}
        onClose={handleClose1}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Grid container lg={12} onClick={() => { setOpen(false) }}>
          <Grid lg={4} md={4} sm={3} xs={0}></Grid>
          <Grid lg={4} md={4} sm={3} xs={0}></Grid>
          <Grid
            lg={4}
            md={4}
            sm={6}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              marginTop: 101,
              height: "auto",
            }}
          >
            {/* <div className={styles.popoverOne}>
              <ArrowDropUpSharpIcon
                color="primary"
                fontSize="large"
                sx={{ color: "#ffff", fontSize: 70 }}
              />
            </div> */}
            <div className={styles.firstDivNtf} >
              <div className={styles.secondDivNtf} >
                <div className={styles.responsive}>
                  <ArrowDropUpSharpIcon
                    color="primary"
                    fontSize="large"
                    sx={{ color: "#ffff", fontSize: 70 }}
                  />
                </div>
                <div className={styles.nIcon}>
                  <h3
                    style={{
                      paddingInlineStart: 10,
                      margin: 0,
                      color: "rgba(76, 25, 201,0.9)",
                      // border: "1px solid red",
                    }}
                  >
                    Notification
                  </h3>
                  <CloseOutlinedIcon
                    fontSize="medium"
                    color="error"
                    className={styles.closeIcon}
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </div>
                <div className={styles.mainCard}>
                  <div
                    style={{
                      marginTop: 10,
                      paddingTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      elivation: 5,
                      textAlign: "center",
                      // backgroundColor: "yellow",
                      overflow: "auto",
                      margin: 5,
                    }}
                  >
                    <div>
                      {_.isArray(finalNotification) &&
                      !_.isEmpty(finalNotification)
                        ? finalNotification.map((item, ind) => {
                            const dd = new Date(item?.sent_on * 1000) ;
                            const ddDate = moment(dd).format('DD MMM YYYY h:mm:ss A');
                            return (
                              <div
                                key={`notifHome${ind}`}
                                style={{
                                  backgroundColor: item?.isRead
                                    ? "#e9ecef"
                                    : "#d9d9d9",
                                  // item?.isRead ? "red" : "yellow",
                                }}
                                className={styles.nCard}
                                onClick={() => {
                                  checkRead(item);
                                  navigate("/FirstPage", {
                                    state: {
                                      title: item?.title,
                                      id: item?.id,
                                      message: item?.body,
                                      // image: item?.img,
                                      time: ddDate,
                                      isRead: item?.isRead,
                                      type: item?.type,
                                    },
                                  });
                                  setShowMenu1(false);
                                }}
                              >
                                <div className={styles.imgDiv}>
                                  {/* <img
                                src={item.img}
                                alt="notification user"
                                className={styles.nImage}
                              /> */}
                                </div>
                                <div className={styles.titleDiv}>
                                  <h5 className={styles.nTitle}>
                                    {item?.title}
                                  </h5>
                                  <p
                                    style={{
                                      color:
                                        item?.isRead == true ? "gray" : "black",
                                      fontSize: 12,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {(item?.body).substring(0, 120)}
                                  </p>
                                  <p className={styles.timeLine}>
                                    {ddDate}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <h4
                  style={{
                    // border: "1px solid red",
                    color: "rgba(76, 25, 201,0.9)",
                    textAlign: "center",
                    padding: 5,
                    paddingTop: 7,
                    marginTop: 1,
                    marginBottom: 0,
                  }}
                  onClick={() => {
                    // navigate("/Notification", { state: { array: notificationStaticArray } });
                    navigate("/Notification");
                    setShowMenu1(false);
                  }}
                >
                  View All Notification
                </h4>
              </div>
            </div>
          </Grid>
        </Grid>
      </Modal>
      </Grid>
    </Grid>
  );
};

export default CHeader;
