import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import authActions from "./Redux/reducers/auth/actions";
import { store } from "./Redux/store/configureStore";

const firebaseConfig = {
    apiKey: "AIzaSyAnDZenOGn4r9dcwFAAEOjVXvKgcV-l6nM",
    authDomain: "beyond-infinity-e6423.firebaseapp.com",
    projectId: "beyond-infinity-e6423",
    storageBucket: "beyond-infinity-e6423.appspot.com",
    messagingSenderId: "39793971449",
    appId: "1:39793971449:web:b561496ebfd2b66764d228",
    measurementId: "G-35BBXP75WC"
  };

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const askForPermissionToReceiveNotifications = () => {
  return getToken(messaging, {vapidKey: 'BH1fxAl9VWqLlMLf7iC9p1YJoA6hk25wukcoopx5heSasGnJfLsoOsTU4eRmrshB8tPu2FYxSkthkprX2xZaiRI'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      store.dispatch(authActions.setUserUUID(currentToken));
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export function onMessageListener() {
  return new Promise((resolve, reject) => {
    onMessage(messaging, (payload) => {
      const notiData = payload?.notification;
      store.dispatch(authActions.setNotiData(notiData));
      store.dispatch(authActions.displayNotificationPopUp(true));
      setTimeout(() => {
        store.dispatch(authActions.displayNotificationPopUp(false));
      }, 5000);
    });
  });
}
