import React from "react";
import useStyles from "./styles";
import Grid from "@mui/material/Grid";

const CFooter = () => {
  const styles = useStyles();
  return (
    <Grid container className={styles.homePage} lg={12}>
      <Grid item className={styles.footerText1} lg={12} md={12} sm={12} xs={12}>
        <p className={styles.copyLine}>
          © Copyright 2022 ☞ 10 to Infinity, LLC - All Rights Reserved
        </p>
      </Grid>
      {/* <Grid item lg={6} md={6} sm={6} xs={12}>
        <Grid className={styles.footerText} gap={2}>
          <li className={styles.liList}>
            <a href="#" className={styles.navBar}>
              TEAMS
            </a>
          </li>
          <p style={{ color: "#ffff" }}>|</p>
          <li className={styles.liList}>
            <a href="#" className={styles.navBar}>
              PRIVECY NOTICE
            </a>
          </li>
          <p style={{ color: "#ffff" }}>|</p>
          <li className={styles.liList}>
            <a href="#" className={styles.navBar}>
              SUPPORT
            </a>
          </li>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default CFooter;
