// import logo from "./logo.svg";
import "./App.css";
import CFooter from "./Components/CFooter";
import CHeader from "./Components/CHeader";
import { Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Login from "./Screen/Login";
import SignUp from "./Screen/SignUp";
import Navigation from "././Navigation";
import CHome from "./Components/CHome";
import { initializeApp } from "firebase/app";
import NotificationPopup from "./Components/NotificationPopup";
import Notification from "./Screen/Notification";
import Logout from "./Screen/Logout";
import _ from "lodash";
import FirstPage from "./Screen/FirstPage";
import ImageWindow from "./Screen/ImageWindow";



const firebaseConfig = {
  apiKey: "AIzaSyAnDZenOGn4r9dcwFAAEOjVXvKgcV-l6nM",
  authDomain: "beyond-infinity-e6423.firebaseapp.com",
  projectId: "beyond-infinity-e6423",
  storageBucket: "beyond-infinity-e6423.appspot.com",
  messagingSenderId: "39793971449",
  appId: "1:39793971449:web:b561496ebfd2b66764d228",
  measurementId: "G-35BBXP75WC",
};

function App() {
  initializeApp(firebaseConfig);
  const uname = JSON.parse(localStorage.getItem("pwaUserDetails"));

  return (
    <Grid
      container
      lg={12}
      style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
    >
      <Grid
        container
        lg={12}
        alignItems="center"
        style={{
          display:"flex",
          alignItems:"center",
          height: "15vh",
          minHeight:"15vh",
          // backgroundColor: "black",
          // borderBottom: "4px solid rgb(76 25 201)",
          // border:"5px solid red",
          position:"sticky",
        }}
      >
        <CHeader
          name={
            _.isUndefined(uname?.name) || _.isEmpty(uname?.name)
              ? ""
              : uname?.name
          }
        />
      </Grid>
      <Grid
        lg={12}
        sm={12}
        md={12}
        xs={12}
        style={{
          background:
            "linear-gradient(90deg, rgb(76, 25, 201) 30%, rgba(250,110,252,1) 90%)",
          height: "1vh",

        }}
      ></Grid>
      <Grid
        container
        lg={12}
        style={{ height: "73vh", overflow: "auto" }}
        className="container"
      >
        <Routes>
          <Route path="/" element={<CHome />}></Route>
          <Route path="Login" element={<Login />}></Route>
          <Route path="FirstPage" element={<FirstPage />}></Route>
          <Route path="Signup" element={<SignUp />}></Route>
          <Route path="Logout" element={<Logout />}></Route>
          <Route path="Notification" element={<Notification />}></Route>
          <Route path="ImageWindow" element={<ImageWindow />}></Route>
        </Routes>
      </Grid>
      <Grid
        lg={12}
        sm={12}
        md={12}
        xs={12}
        style={{
          background:
            "linear-gradient(90deg, rgb(76, 25, 201) 30%, rgba(250,110,252,1) 90%)",
          height: "1vh",
        }}
      ></Grid>
      <Grid
        container
        lg={12}
        style={{
          height: "10vh",
          backgroundColor: "pink",
          position: "relative",
          // borderTop: "4px solid rgb(76 25 201)",
        }}
      >
        <NotificationPopup />
        <CFooter />
      </Grid>
    </Grid>
  );
}

export default App;
