import React from "react";
import useStyles from "./styles";
import Grid from '@mui/material/Grid';

const SignUp = () => {
    const classes = useStyles() ;
    return (
        <Grid className={classes.homePage}>
            <h1 style={{color:"#ffff"}}>My registeration Page </h1>
        </Grid>
    )
}

export default SignUp ;