import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import {
  askForPermissionToReceiveNotifications,
  onMessageListener,
} from "../../push-notification";
import { Link ,useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import _ from "lodash";
import { notificationStaticArray } from "../../Config/Static_Data";
import authActions from "../../Redux/reducers/auth/actions";


const CHome = (props) => {
 const styles = useStyles();
  const uname = JSON.parse(localStorage.getItem("pwaUserDetails"));
  const {notificationData } = useSelector((state) => state.auth);
  const NotiData = JSON.parse(localStorage.getItem("notificationData"));
  const arrNum = _.isArray(NotiData) && !_.isEmpty(NotiData) ? NotiData.length : 0;
  const dispatch = useDispatch();
  useEffect(() => {
    askForPermissionToReceiveNotifications();
    onMessageListener();
  }, []);

  return (
    <Grid
      container
      lg={12}
      style={{
        backgroundImage:
          'url("https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png")',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className={styles.circle}></div>
      <div className={styles.circle1}></div>
      <div className={styles.circle2}></div>
      <div className={styles.circle3}></div>
      <Grid
        container
        lg={12}
        style={{
          // width: "100%",
          // height: "75%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          // backgroundImage : `url("https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png")` ,
          // backgroundRepeat:'no-repeat',
          color: "#ffff",
          display: "flex",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <div style={{ textAlign: "center", marginTop: 10 }}>
        {
          _.isUndefined(uname?.name) || _.isEmpty(uname?.name) ? null : (
          <h1 className={[styles.title]}>
            Hi ,{" "}
            <span style={{ color: "#ffff", fontSize: "2.0rem" }}>
              {_.isUndefined(uname?.name) || _.isEmpty(uname?.name) ? '' : uname?.name} !!
            </span>
          </h1>
          )
        }
          <h1 style={{ color: "#ffff", fontSize: "2.0rem" }}>
            Welcome to <span className={styles.title}>Beyond Infinity</span>
          </h1>
        </div>
        <Grid lg={10} md={8} sm={8} className={styles.nCard} gap={1}>
          {_.isUndefined(uname) || _.isEmpty(uname) ? null : (
            <Grid item lg={4} md={10} sm={12} xs={12}>
              <Link to="/Notification" style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: 20,
                    padding: 10,
                    background:
                      "linear-gradient(90deg, rgb(76, 25, 201) 30%, rgba(250,110,252,1) 90%)",
                  }}
                >
                  <div className={styles.ntitle}>
                    <NotificationsActiveOutlinedIcon
                      className={styles.nIcon}
                      sx={{ fontSize: 60, color: "#ffff" }}
                    />
                    <h1 style={{ color: "#ffff" }}>Notification</h1>
                  </div>
                  <div className={styles.number}>
                    <h1 style={{ color: "#ffff" }}>{arrNum}</h1>
                  </div>
                </Card>
              </Link>
            </Grid>
          )}
          {/* <Grid item lg={3} md={5} sm={12} sx={12}>
          <Card
            className={styles.userTitle}
            style={{
              borderRadius: 20,
              elivation: 10,
              background:
                "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,1) 42%, rgba(250,110,252,1) 100%)",
            }}
          >
            <div className={styles.numberOne}>
              <Avatar
                sx={{
                  bgcolor: deepOrange[500],
                  fontSize: 30,
                  width: 70,
                  height: 70,
                  color: "#ffff",
                }}
              >
                {_.isEmpty(uname?.name) ? "BI" : (uname?.name).substring(0, 1)}
              </Avatar>
            </div>
            <h2 style={{ color: "#ffff" }}>{uname?.name}</h2>
          </Card>
        </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CHome;
