import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import { useLocation } from "react-router-dom";
import CImageCard from "../../Components/CImageCard";

const FirstPage = (props) => {
  const styles = useStyles();
  const location = useLocation();

  return (
    <Grid
      container
      lg={12}
      style={{
        backgroundImage:
          'url("https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png")',
        // height: "100vh",
        // width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Grid container lg={12} style={{}}>
        <CImageCard
          // images={location?.state?.image}
          link ={location?.state?.type == "newsletter" ? true : false }
          title={location?.state?.title}
          time={location?.state?.time}
          message={location?.state?.message}
        />
      </Grid>
    </Grid>
  );
};

export default FirstPage;
