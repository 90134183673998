import types from "./actions";

const initialState = {
  userdata: {},
  dropdowndata: {},
  registerParamas: [],
  notificationData:[],
  mainToken: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      localStorage.setItem("LoginData", JSON.stringify(action.userdata));
      return {
        ...state,
        userdata: action.userdata,
      };

    case types.SET_DROPDOWN_DATA:
      localStorage.setItem("pwaUserDetails", JSON.stringify(action.dropdowndata));
      return {
        ...state,
        dropdowndata: action.dropdowndata,
      };

    case types.SET_REGPARAMS_DATA:
      return {
        ...state,
        registerParamas: action.registerParamas,
      };

    case types.SET_MAIN_TOKEN:
      return {
        ...state,
        mainToken: action.mainToken,
      };

      case types.SET_NOTIFICATION_DATA:
        return {
          ...state,
          notificationData: action.notificationData,
        };

    default:
      return state;
  }
}
