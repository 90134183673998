import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";
// import Images from "../../Config/Images";

const NotificationPopup = () => {
  const { isNotifiy, notiData } = useSelector((state) => state.auth);
  const title = notiData?.title !== "" ? notiData?.title : "-";
  const description = notiData?.msg !== "" ? notiData?.body : "-";
  // const isNotifiy = true;
  // const title = "abc";
  // const description = "khambhat";



  return isNotifiy ? (
    <div
      style={{
        cursor: "pointer",
      }}
    >
      <div className={`notification-container top-right`}>
        <div className="sub-div-for-notification">
          <div className="sub-flex-con">
            <div className="notification-image">
              <img
                loading="lazy"
                // src={Images.header_logo}
                alt={"AppIcon"}
                className="noti-app-logo"
              />
            </div>
            <span className="notification-title">{title}</span>
            <span className="notification-message">{description}</span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default NotificationPopup;
