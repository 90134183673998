import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  homePage: {
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    // border:"3px solid green",
  },
  gridOne: {
    // border: "1px solid green",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor:"gray",
    "@media(max-width:950px)": {
      justifyContent: "flex-start",
      paddingLeft: 10,
    },
  },
  gridTwo: {
    // border: "1px solid red",
    display: "flex",
    alignItems: "center",
    paddingRight: 30,
    justifyContent: "flex-end",
    // backgroundColor:"green",
    "@media(max-width:950px)": {
      display: "none",
    },
  },
  gridThree: {
    display: "none",
    // backgroundColor: "yellow",
    color: "#fff",
    // border: "1px solid green",
    "@media(max-width:950px)": {
      display: "flex",
      alignItems: "center",
      paddingRight: 5,
      justifyContent: "flex-end",
    },
  },
  // ---------------------------
  title: {
    color: "#fa6efc",
    "@media(max-width: 1164px)": {
      fontSize: "1.6rem",
    },
    "@media(max-width: 1100px)": {
      fontSize: "1.5rem",
    },
    "@media(max-width: 430px)": {
      fontSize: "20px",
    },
    "&:hover ": {
      color: "#00ace0",
    },
  },
  logoOn: {
    display: "none",
    border: "1px solid green",
    "@media(max-width: 950px)": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      color: "#FFFF",
    },
  },
  img: {
    width: 200,
    height: 90,
    // marginRight: 30,
    "@media(max-width: 1020px)": {
      marginRight: "0px",
      width: 130,
      height: 130,
    },
    "@media(max-width: 985px)": {
      marginRight: "0px",
      width: 120,
      height: 120,
    },
    "@media(max-width: 650px)": {
      marginRight: "0px",
      width: 100,
      height: 70,
    },
    "@media(max-width: 400px)": {
      marginRight: "0px",
      width: 70,
      height: 70,
      gap: 0,
    },
    "@media(max-width: 360px)": {
      marginRight: "0px",
      width: 70,
      height: 50,
      gap: 0,
    },
  },
  listData: {
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "2rem",
  },
  listScreen: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 20px",
    //  border:"1px solid red",
  },
  nListScreen: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 2px",
    // border:"1px solid red",
    borderBottomWidth:"1px",
    borderBottomColor:"rgb(76 25 201)",
    borderBottomStyle:"solid",
  },
  number: {
    // border: "2px solid green",
    borderRadius: 30,
    position: "absolute",
    zIndex: 1,
    width:20,
    height:20,
    top: 40,
    padding: 1,
    fontSize: 14,
    color: "#fa6efc",
    marginLeft: 23,
    backgroundColor: "#fff",
    textAlign:'center',
    justifyContent:'center',
    "&:hover ": {
      color: "#00ace0",
    },
  },
  drowerNum: {
    // border: "2px solid green",
    elivation: 10,
    position: "absolute",
    zIndex: 1,
    fontSize: 13,
    padding: 5,
    borderRadius: 50,
    top: 90,
    marginLeft: 15,
    color: "#ffff",
    backgroundColor: "gray",
  },
  drawerScreen: {
    listStyle: "none",
    textAlign: "center",
    // border: "1px solid red",
  },
  drawerScreenMenu: {
    listStyle: "none",
    textAlign: "center",
    // border: "1px solid red",
  },
  listScreenMenu: {
    listStyle: "none",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderBottomWidth:"1px",
    borderBottomColor:"rgb(76 25 201)",
    borderBottomStyle:"solid",
    padding: "10px 12px",
    "&:hover ": {
      color: "#00ace0",
    },
  },
  notification:{
    color: "#fa6efc",
    "&:hover ": {
      color: "#00ace0",
    },
  },
  nCard:{
    display: "flex",
    marginBottom: 15,
    maxWidth: "700px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 1px",
    borderRadius: 10,
    padding: "5px 5px",
    "&: hover ": {
      backgroundColor:"yellow",
      color: "#00ace0",
      border:"4px solid green",
    },
  },
  screen: {
    // padding: 30,
    color: "#fa6efc",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    "&:hover ": {
      color: "#00ace0",
    },
    "@media (max-width:450px)": {
      marginRight: "0px",
    },
  },
  iconRightSpace: {
    marginRight: 20,
  },

  drawerMenu: {
    // border: "2px solid green",
    display: "none",
    // backgroundColor:"yellow",
    "@media(max-width: 955px)": {
      color: "#ffff",
      display: "flex",
      justifyContent: "center",
      paddingBottom: 30,
      height: 100,
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  },
  popover: {
    position: "absolute",
    zIndex: 999,
    top: 100,
    marginRight: "7%",
    right: -35,
    overflow: "hidden",
    // height: "70%",
    backgroundColor: "#fff",
    textAlign: "left",
    // border: "1px solid red",
    padding: 10,
    // backgroundColor:"pink",
    borderRadius: 10,
    // width: "400px",
    "@media(max-width:650px)": {
      // marginRight:"10%",
      left: 30,
      width: "80%",
    },
    "@media(max-width:400px)": {
      marginRight: 30,
    },
  },
  responsive: {
    position: "fixed",
    zIndex: 999,
    top: 62,
    right: 30,
    "@media(max-width:950px)": {
      display:"none",
    },
  },
  popoverOne: {
    position: "absolute",
    zIndex: 999,
    top: 62,
    borderRadius: 50,
    marginRight: "10.2%",
    right: -45,
    overflow: "hidden",
    height: "auto",
    // backgroundColor: "#fff",
    textAlign: "left",
    // border: "1px solid red",
    // width: "400px",
    "@media(max-width:1725px)": {
      marginRight: "8%",
    },
    "@media(max-width:1450px)": {
      marginRight: "7%",
    },
    "@media(max-width:950px)": {
      display: "none",
    },
  },
  nIcon:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:10,
    borderBottom:"1px solid green",
  },
  closeIcon:{
    // border:"1px solid #1234",
    elivation:55,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    backgroundColor:"#ffff",
    padding:1,
    borderRadius:5,
    "&:hover ":{
      color:"red",
    }
  },
  mainCard: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    // maxHeight: "400px",
    height:"auto",
    maxHeight: "50vh",
    borderRadius: 10,
    // padding:3,
    overflow: "auto",
    // backgroundColor:"pink",
    "&::-Webkit-Scrollbar": { display: null, width: 0, height: 0 },
    "&:-WebkitScrollbar": { display: null, width: 0, height: 0 },
    "& hover":{
      border:"5px solid red",
    }
  },
  drawerSabMenu: {
    backgroundColor: "#111",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 10,
    height: "100vh",
    width: "60vw",
    // border:"3px solid red",
    padding: "10px 1px",
    "@media(min-width:1000px)": {
      display: "none",
    },
  },
  imgDiv:{
      // border: "2px solid green",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
  },
  nImage:{
    width: 60,
    height: 60,
    borderRadius: 10,
    objectFit: "fill",
    backgroundOrigin: "content-box",
  },
  titleDiv: {
    textAlign: "left",
    width: "100%",
    paddingInlineStart: 10,
    // border: "2px solid red",
  },
  nTitle: {
    color: "#111",
    margin: "10px 2px",
  },
  timeLine: {
    color: "#111",
    fontSize: 10,
    marginRight:5,
    fontWeight: "bold",
    float: "right",
    marginTop: 4,
    marginBottom: 0,
  },
  firstDivNtf: {
    borderRadius: 10,
    marginRight: 20,
    marginLeft: 20,
    background:
      "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,0.9) 42%, rgba(250,110,252,1) 100%)",
    "@media(max-width:950px)": {
      display: "none",
    },
  },
  secondDivNtf: {
    padding: 10,
    borderRadius: 10,
    // marginRight: 20,
    // marginLeft: 20,
    // backgroundColor: "#ffff",
    backgroundColor: "rgba(255,255,255, 0.8)",
  },
}));

export default useStyles;
