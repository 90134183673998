const prod = process.env.NODE_ENV === "production";
const baseUrl = prod ? 'https://stg.beyondinfinity.club/api_v1/' : 'https://stg.beyondinfinity.club/api_v1/';

export const Setting = {
  baseUrl,
  api: baseUrl,

//   FACEBOOK_APP_ID: "364163242295682",

//   GOOGLE_CLIENT_ID:
//     "232985582305-3krv8lsoda60vnv4okp952qgpgo4lahd.apps.googleusercontent.com",
//   GOOGLE_CLIENT_SECRET_KEY: "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",

  endpoints: {
    //common
    login: "login",
    register: "register",
    userData: "user",
    registrationParams: "registrationParams",
    logout: "logout",
    notificationHistory: "history",
  },



};