import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    // border: "3px solid blue",
    padding: 20,
    // width: "30vw",
    // borderRadius: 10,
  },
  title: {
    color: "#fa6efc",
    fontSize: '2.6rem',
    "@media(max-width: 1100px)": {
      fontSize: "1.5rem",
    },
    "@media(max-width: 430px)": {
      fontSize: "1.5rem",
    },
  },
  
  circle:{
    width: "10rem",
    height: "10rem",
    position: "absolute",
    borderRadius: "50%",
    right: 30,
    top: "20%",
    background: "linear-gradient(90deg, rgba(76, 25, 201,0.2) 30%, rgba(250,110,252,0.3) 90%)",
    "@media(max-width: 600px)": {
      display:'none'
    },
  },
  circle1:{
    width: "8rem",
    height: "8rem",
    position: "absolute",
    borderRadius: "50%",
    left: 20,
    bottom: "14%",
    background: "linear-gradient(90deg, rgba(76, 25, 201,0.3) 30%, rgba(250,110,252,0.3) 90%)",
    "@media(max-width: 600px)": {
      display:'none'
    },
  },
  circle2:{
    width: "8rem",
    height: "8rem",
    position: "absolute",
    borderRadius: "50%",
    right: "8%",
    top: "40%",
    background: "linear-gradient(90deg, rgba(76, 25, 201,0.2) 30%, rgba(250,110,252,0.2) 90%)",
    "@media(max-width: 650px)": {
      display:'none'
    },
  },
  nCard: {
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "#ffff",
    padding: 20,
    height:"auto",
    elivation:10,
    margin:"10px 10px",
    borderRadius:10,
    "@media(max-width:750px) ": {
      display:"block",
    }
  },
  ntitle: {
    // border:"1px solid red",
    width: "100%",
    display: "flex",
    padding: "10px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  userTitle: {
    // border: "1px solid red",
    display: "flex",
    padding:5,
    borderRadius:30,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  number: {
    // border: "1px solid red",
    width: "50%",
    height: "100%",
    textAlign: "center",
    padding:15,
    // boxShadow:
    // "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
    margin:15,
    borderRadius:20,
  },
  numberOne: {
    // border: "1px solid red",
    textAlign: "center",
    boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
    margin:15,
    padding:15,
    borderRadius:20,
  },
  nIcon: {
    // border:"1px solid red",
    padding: 20,
    borderRadius: 10,
    // boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
  },
}));

export default useStyles;
