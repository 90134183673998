import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Grid from "@mui/material/Grid";
import { json, Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Alert, Avatar, CssBaseline, Tabs, Typography } from "@mui/material";
import { Tab } from "@mui/material";
import _, { isArray, isEmpty } from "lodash";
import {
  getApiData,
  getApiDataProgress,
  getAPIProgressData,
} from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import { Container } from "@mui/system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { notificationStaticArray } from "../../Config/Static_Data";
import { Popover } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import logo2 from "../../assets/images/logo2.png";

const Login = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { useruuid, registerParamas } = useSelector((state) => state.auth);
  console.log("useruuid =====>>> ", useruuid);
  const { setRegParamsData, setMainToken, setNotificationData } = authActions;
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [userData, setUserData] = useState({});
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [regData, setRegData] = useState([
    {
      fname: "",
      lname: "",
      country: "",
      mobileno: "",
      uname: "",
      email: "",
      password: "",
    },
  ]);

  const [pwaVerifyBool, setPwaVerifyBool] = useState(false);
  const [isFirstBool, setIsFirstBool] = useState(false);
  const [signupBool, setSignupBool] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorPasswordMsg, setErrorPasswordMsg] = useState(false);
  const [errorSnameMsg, setErrorSnameMsg] = useState(false);
  const [errorSnameMsgTxt, setErrorSnameMsgTxt] = useState("");
  const [errorUnameMsg, setErrorUnameMsg] = useState(false);
  const [errorFnameMsg, setErrorFnameMsg] = useState(false);
  const [errorLnameMsg, setErrorLnameMsg] = useState(false);
  const [errorConMsg, setErrorConMsg] = useState(false);
  const [errorEmailMsg, setErrorEmailMsg] = useState(false);
  const [errorEmailMsgTxt, setErrorEmailMsgTxt] = useState("");
  const [errorPassMsg, setErrorPassMsg] = useState(false);
  const [errorPassMsgTxt, setErrorPassMsgTxt] = useState("");
  const [errorMoNumMsg, setErrorMoNumMsg] = useState(false);
  const [errorMoNumMsgTxt, setErrorMoNumMsgTxt] = useState("");
  const [listData, setListData] = useState(false);
  const [listValue, setListValue] = useState("");
  const [textColor, setTextColor] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(false);
  const [allCountryData, setAllCountyrData] = useState({});
  const [pwaLoaderBool, setPwaLoaderBool] = useState(false);
  const [regBool, setRegBool] = useState(false);
  const [loginBool, setLoginBool] = useState(false);
  const [finalBool, setFinalBool] = useState(false);
  const [finalAns, setFinalAns] = useState("");
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [arraydata, setArraydata] = useState([]);
  let cName;

  useEffect(() => {
    localStorage.setItem(
      "notificationLocalStoreArray",
      JSON.stringify(notificationStaticArray)
    );
    dispatch(setNotificationData(notificationStaticArray));
  }, []);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsFirstBool(false);
    setPwaVerifyBool(false);
    setRegData({ ...regData, sname: "" });
  };

  // SHA256 function
  function SHA256(s) {
    var chrsz = 8;
    var hexcase = 0;

    function safe_add(x, y) {
      var lsw = (x & 0xffff) + (y & 0xffff);
      var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
      return (msw << 16) | (lsw & 0xffff);
    }

    function S(X, n) {
      return (X >>> n) | (X << (32 - n));
    }
    function R(X, n) {
      return X >>> n;
    }
    function Ch(x, y, z) {
      return (x & y) ^ (~x & z);
    }
    function Maj(x, y, z) {
      return (x & y) ^ (x & z) ^ (y & z);
    }
    function Sigma0256(x) {
      return S(x, 2) ^ S(x, 13) ^ S(x, 22);
    }
    function Sigma1256(x) {
      return S(x, 6) ^ S(x, 11) ^ S(x, 25);
    }
    function Gamma0256(x) {
      return S(x, 7) ^ S(x, 18) ^ R(x, 3);
    }
    function Gamma1256(x) {
      return S(x, 17) ^ S(x, 19) ^ R(x, 10);
    }

    function core_sha256(m, l) {
      var K = new Array(
        0x428a2f98,
        0x71374491,
        0xb5c0fbcf,
        0xe9b5dba5,
        0x3956c25b,
        0x59f111f1,
        0x923f82a4,
        0xab1c5ed5,
        0xd807aa98,
        0x12835b01,
        0x243185be,
        0x550c7dc3,
        0x72be5d74,
        0x80deb1fe,
        0x9bdc06a7,
        0xc19bf174,
        0xe49b69c1,
        0xefbe4786,
        0xfc19dc6,
        0x240ca1cc,
        0x2de92c6f,
        0x4a7484aa,
        0x5cb0a9dc,
        0x76f988da,
        0x983e5152,
        0xa831c66d,
        0xb00327c8,
        0xbf597fc7,
        0xc6e00bf3,
        0xd5a79147,
        0x6ca6351,
        0x14292967,
        0x27b70a85,
        0x2e1b2138,
        0x4d2c6dfc,
        0x53380d13,
        0x650a7354,
        0x766a0abb,
        0x81c2c92e,
        0x92722c85,
        0xa2bfe8a1,
        0xa81a664b,
        0xc24b8b70,
        0xc76c51a3,
        0xd192e819,
        0xd6990624,
        0xf40e3585,
        0x106aa070,
        0x19a4c116,
        0x1e376c08,
        0x2748774c,
        0x34b0bcb5,
        0x391c0cb3,
        0x4ed8aa4a,
        0x5b9cca4f,
        0x682e6ff3,
        0x748f82ee,
        0x78a5636f,
        0x84c87814,
        0x8cc70208,
        0x90befffa,
        0xa4506ceb,
        0xbef9a3f7,
        0xc67178f2
      );
      var HASH = new Array(
        0x6a09e667,
        0xbb67ae85,
        0x3c6ef372,
        0xa54ff53a,
        0x510e527f,
        0x9b05688c,
        0x1f83d9ab,
        0x5be0cd19
      );
      var W = new Array(64);
      var a, b, c, d, e, f, g, h, i, j;
      var T1, T2;

      m[l >> 5] |= 0x80 << (24 - (l % 32));
      m[(((l + 64) >> 9) << 4) + 15] = l;

      for (var i = 0; i < m.length; i += 16) {
        a = HASH[0];
        b = HASH[1];
        c = HASH[2];
        d = HASH[3];
        e = HASH[4];
        f = HASH[5];
        g = HASH[6];
        h = HASH[7];

        for (var j = 0; j < 64; j++) {
          if (j < 16) W[j] = m[j + i];
          else
            W[j] = safe_add(
              safe_add(
                safe_add(Gamma1256(W[j - 2]), W[j - 7]),
                Gamma0256(W[j - 15])
              ),
              W[j - 16]
            );

          T1 = safe_add(
            safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]),
            W[j]
          );
          T2 = safe_add(Sigma0256(a), Maj(a, b, c));

          h = g;
          g = f;
          f = e;
          e = safe_add(d, T1);
          d = c;
          c = b;
          b = a;
          a = safe_add(T1, T2);
        }

        HASH[0] = safe_add(a, HASH[0]);
        HASH[1] = safe_add(b, HASH[1]);
        HASH[2] = safe_add(c, HASH[2]);
        HASH[3] = safe_add(d, HASH[3]);
        HASH[4] = safe_add(e, HASH[4]);
        HASH[5] = safe_add(f, HASH[5]);
        HASH[6] = safe_add(g, HASH[6]);
        HASH[7] = safe_add(h, HASH[7]);
      }
      return HASH;
    }

    function str2binb(str) {
      var bin = Array();
      var mask = (1 << chrsz) - 1;
      for (var i = 0; i < str.length * chrsz; i += chrsz) {
        bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
      }
      return bin;
    }

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    }

    function binb2hex(binarray) {
      var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
      var str = "";
      for (var i = 0; i < binarray.length * 4; i++) {
        str +=
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
      }
      return str;
    }

    s = Utf8Encode(s);
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
  }

  //  SIGN IN CLICK
  const signIn = (e) => {
    e.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (isEmpty(data.email)) {
      setErrorMsg(true);
    } else if (isEmpty(data.password)) {
      setErrorMsg(false);
      setErrorPasswordMsg(true);
    } else {
      setErrorMsg(false);
      setErrorPasswordMsg(false);
      userLogin();
    }
    // else if (!emailRegex.test(data.email)) {
    //   alert("Please enter valid email");
    // } else if (isEmpty(data.password)) {
    //   alert("Please enter Password");
    // } else {
    // let arr = [];
    // arr = JSON.parse(localStorage.getItem("LoginData"));
    // arr.push({ ...data });
  };

  // SIGN UP CLICK
  const signup = (e) => {
    e.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const numVal = /^[0]?[6789]\d{9}$/;
    const numVal = /^[0-9]+$/;
    if (!pwaVerifyBool) {
      if (isEmpty(regData?.sname)) {
      } else {
        verifyPwaApiCall();
      }
    } else {
      let tempBool = false;
      if (isEmpty(regData.uname)) {
        tempBool = true;
        // allErrorFalse();
        setErrorUnameMsg(true);
      }
      if (isEmpty(regData.fname)) {
        tempBool = true;
        // allErrorFalse();
        setErrorFnameMsg(true);
      }
      if (isEmpty(regData.lname)) {
        tempBool = true;
        // allErrorFalse();
        setErrorLnameMsg(true);
      }
      if (isEmpty(regData.country)) {
        tempBool = true;
        // allErrorFalse();
        setErrorConMsg(true);
      }
      if (isEmpty(regData.mobileno)) {
        tempBool = true;
        // allErrorFalse();
        setErrorMoNumMsg(true);
        setErrorMoNumMsgTxt("Mobile number Is not Empty");
      }
      if (regData?.mobileno?.length != 10) {
        tempBool = true;
        // allErrorFalse();
        setErrorMoNumMsg(true);
        setErrorMoNumMsgTxt("Enter 10 digit Mobile number");
      }
      if (!numVal.test(regData.mobileno)) {
        tempBool = true;
        // allErrorFalse();
        setErrorMoNumMsg(true);
        setErrorMoNumMsgTxt("Enter 10 digit Mobile number");
      }
      if (isEmpty(regData.email)) {
        tempBool = true;
        // allErrorFalse();
        setErrorEmailMsg(true);
        setErrorEmailMsgTxt("Please enter mandatory fields");
      }
       if (!emailRegex.test(regData.email)) {
        tempBool = true;
        // allErrorFalse();
        setErrorEmailMsg(true);
        setErrorEmailMsgTxt("Please enter valid email address");
      }
      if (isEmpty(regData.password)) {
        tempBool = true;
        // allErrorFalse();
        setErrorPassMsg(true);
        setErrorPassMsgTxt("Please Enter Password");
      }
      if (tempBool === false) {
        setRegBool(false);
        allErrorFalse();
        setErrorPassMsg(false);
        // let array = [];
        // array = JSON.parse(localStorage.getItem("RegData"));
        // array.push({...regData})
        // console.log("array ===> ", array);
        localStorage.setItem("RegData", JSON.stringify({ ...regData }));
        signUpAPICall();
      }
    }
  };
  const allErrorFalse = () => {
    setErrorSnameMsg(false);
    setErrorUnameMsg(false);
    setErrorFnameMsg(false);
    setErrorLnameMsg(false);
    setErrorConMsg(false);
    setErrorEmailMsg(false);
    setErrorMoNumMsg(false);
    setErrorPassMsg(false);
  };

  const clearData = () => {
    data.email = "";
    data.password = "";
  };

  // loginApiCall
  async function userLogin() {
    setLoginBool(true);
    const loginData = {
      username: data.email,
      password: data.password,
      uuid: useruuid ? useruuid : "StaticUUId",
    };
    const header = {
      Authorization: "5e9f01a0-78aa-48b3-ad06-06dd53a029a8",
    };

    try {
      let endPoint = Setting.endpoints.login;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        loginData,
        header
      );
      if (response?.success === 1) {
        alert(useruuid);
        localStorage.setItem("LoginData", JSON.stringify(data));
        setLoginBool(false);
        toast.success("Login Done Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          closeButton: false,
        });
        localStorage.setItem(
          "pwaAccessToken",
          JSON.stringify(response.message)
        );
        dispatch(setMainToken(response.message));
        UserDataAPICall(loginData?.username, response);
      } else {
        setLoginBool(false);
        toast.warning(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          closeButton: false,
        });
        navigate("/Login");
        setInterval(() => {
          window.location.reload(false);
        }, 5000);
      }
    } catch (err) {
      setLoginBool(false);
      console.log("Catch Part", err);
      toast.error("Warning Error", err, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  React.useEffect(() => {
    allFieldsInfoApiCall();
    let tempArr = [];
    setTimeout(() => {
      registerParamas.map((item) => {
        if (item?.name === "country") {
          const objectArray = Object.entries(item?.options);

          objectArray.forEach(([key, value]) => {
            let tempOb = {
              [key]: value,
            };
            tempArr.push(tempOb);
          });
        }
      });
      setAllCountyrData(tempArr);
      setArraydata(tempArr);
    }, 1500);
  }, []);

  // All fields information API CAll-----------
  async function allFieldsInfoApiCall() {
    const header = {
      Authorization: "5e9f01a0-78aa-48b3-ad06-06dd53a029a8",
    };
    try {
      let endPoint = Setting.endpoints.registrationParams;
      const response = await getApiData(endPoint, "GET", {}, header);
      if (response?.success === 1) {
        if (!_.isEmpty(response)) {
          dispatch(setRegParamsData(response?.message));
        }
      }
    } catch (err) {
      console.log("Catch Part---All----userdataapicall---", err);
    }
  }

  // userDataAPicall
  async function UserDataAPICall(uname, loginDetails) {
    const Data = {
      username: uname,
    };
    try {
      let endPoint = Setting.endpoints.userData;
      const response = await getAPIProgressData(endPoint, "POST", Data, {});
      if (response?.success === 1) {
        if (!_.isEmpty(response?.message)) {
          let tempOb = {};
          tempOb = response?.message;
          tempOb.accessToken = loginDetails?.message;
          setUserData(tempOb);
          localStorage.setItem("pwaUserDetails", JSON.stringify(tempOb));
          navigate("/");
          window.location.reload(false);
        }
      }
    } catch (err) {
      console.log("Catch Part-------userdataapicall---", err);
    }
  }

  async function signUpAPICall() {
    setRegBool(true);
    const tempSHAPassword = SHA256(regData.password);
    const Data = {
      sponsor: regData?.sname,
      username: regData?.uname,
      first_name: regData?.fname,
      last_name: regData?.lname,
      phone: regData?.mobileno,
      country: regData?.country,
      email: regData?.email,
      password: tempSHAPassword,
      uuid: useruuid ? useruuid : "StaticUUId",
    };
    const header = {
      Authorization: "5e9f01a0-78aa-48b3-ad06-06dd53a029a8",
    };

    console.log("Data ===> ", Data);
    try {
      let endPoint = Setting.endpoints.register;
      const response = await getAPIProgressData(endPoint, "POST", Data, header);
      if (response?.success == 1) {
        localStorage.setItem(
          "pwaAccessToken",
          JSON.stringify(response.message)
        );
        dispatch(setMainToken(response.message));
        UserDataAPICall(Data?.username, response);
      } else {
        setRegBool(false);
        toast.warning(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          closeButton: false,
        });
      }
    } catch (err) {
      setRegBool(false);
      console.log("Catch Part-------signupAPIcall---", err);
    }
  }

  async function verifyPwaApiCall() {
    setIsFirstBool(true);
    setPwaLoaderBool(true);
    const Data = {
      sponsor: regData?.sname,
    };
    const header = {
      Authorization: "5e9f01a0-78aa-48b3-ad06-06dd53a029a8",
    };

    console.log("Data ==verify pwa=> ", Data);
    try {
      let endPoint = Setting.endpoints.register;
      const response = await getAPIProgressData(endPoint, "POST", Data, header);
      if (
        response.message.includes("Sponsor not found or not eligible to enroll")
      ) {
        // toast.warning(response.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 5000,
        //   closeButton: false,
        // });
        setErrorSnameMsg(true);
        setErrorSnameMsgTxt(response?.message);
      } else {
        setPwaVerifyBool(true);
        setErrorSnameMsg(false);
        setSignupBool(true);
      }
      setPwaLoaderBool(false);
    } catch (err) {
      setPwaLoaderBool(false);
      console.log("Catch Part-------signupAPIcall---", err);
    }
  }

  const filterValue = (val) => {
    console.log("val ===> ", val);
    if (val) {
      const newData = allCountryData.filter((item) => {
        // console.log("item ===> ", item);

        // console.log("Object.values(item) ===> ", Object.values(item));
        const itemData = Object.values(item)
          ? Object.values(item).toString().toUpperCase()
          : "".toUpperCase();
        console.log("itemData ===> ", itemData);

        const textData = val.toUpperCase();
        console.log("textData ===> ", textData);
        return itemData.indexOf(textData) > -1;
      });
      setArraydata(newData);
    } else {
      setArraydata(allCountryData);
    }
  };

  return (
    <Grid
      container
      className={styles.homePage}
      onClick={() => {
        if (listData == true) {
          setListData(false);
        }
      }}
    >
      <Grid item lg={6} md={5} sm={5} xs={6} className={styles.imgDiv}>
        <img
          src="https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png"
          style={{ width: "100%", height: "70vh" }}
        />
      </Grid>

      <Grid
        lg={6}
        md={7}
        sm={12}
        xs={12}
        style={{ justifyContent: "center" }}
        onClick={() => {
          if (listData == true) {
            setListData(false);
          }
        }}
      >
        <Grid container className={styles.loginPage}>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: 5,
            }}
          >
            <Tabs
              value={value}
              textColor="secondary"
              indicatorColor="secondary"
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                displa: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3,
              }}
              style={{
                // backgroundColor: 'white',
                padding: 2,
              }}
            >
              <Tab
                label="Sign In"
                {...a11yProps(0)}
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "#fff",
                }}
                style={
                  {
                    // backgroundColor: 'rgba(255,255,255, 0.9)',
                  }
                }
              />
              <Tab
                label="Sign Up"
                disabled
                {...a11yProps(1)}
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "red",
                }}
                style={
                  {
                    // backgroundColor: 'rgba(255,255,255, 0.9)',
                    color:"#ffff"
                  }
                }
              />
            </Tabs>
          </div>
          {value == 0 ? (
            <>
              <Grid container className={styles.loginFormOuter}>
                <Container component="main" maxWidth="sm">
                  <div
                    style={{
                      borderRadius: 10,
                      background:
                        "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,1) 42%, rgba(250,110,252,1) 100%)",
                    }}
                  >
                    <Card
                      style={{
                        padding: "15px 40px",
                        borderRadius: 10,
                        textAlign: "center",
                        marginBottom: 40,
                        // backgroundColor: 'white',
                        backgroundColor: "rgba(255,255,255, 0.9)",
                      }}
                    >
                      <div className={styles.title}>
                        <img
                          src={logo2}
                          style={{ width: 100, height: 100 }}
                          alt="Login Logo"
                        />
                        <h1 style={{ color: "rgb(0,119,190)" }}>
                          Back-Office Signin
                        </h1>
                      </div>
                      <Box
                        component="form"
                        noValidate
                        sx={{
                          // mt: 1,
                          // my: 2,
                          // mx: 6,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          // maxWidth: "100%",
                        }}
                      >
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          error={errorMsg ? true : false}
                          id="email"
                          label="User Name"
                          name="email"
                          autoComplete={"off"}
                          value={data.email}
                          autoFocus
                          color="secondary"
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            borderRadius: 2,
                            boxShadow: "none",
                            backgroundColor: "none",
                          }}
                          onChange={(e) => {
                            setData({ ...data, email: e.target.value });
                          }}
                          helperText={
                            errorMsg ? "* Please Enter Valid UserName" : null
                          }
                        />
                        <TextField
                          margin="normal"
                          required
                          error={errorPasswordMsg ? true : false}
                          fullWidth
                          name="password"
                          label="Password"
                          type={values.showPassword ? "text" : "password"}
                          id="password"
                          value={data.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          autoComplete={"off"}
                          color="secondary"
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            borderRadius: 2,
                            boxShadow: "none",
                            backgroundColor: "none",
                          }}
                          onChange={(e) => {
                            setData({ ...data, password: e.target.value });
                          }}
                          helperText={
                            errorPasswordMsg
                              ? "* Please Enter Valid Password"
                              : null
                          }
                        />

                        {/* <FormControlLabel
                          control={
                            <Checkbox value="remember" color="secondary" />
                          }
                          label="Remember me"
                        /> */}
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            borderRadius: 10,
                            elivation: 10,
                            padding: 2,
                            fontSize: "1rem",
                            fontWeight: "bold",
                            background:
                              "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,1) 42%, rgba(250,110,252,1) 100%)",
                          }}
                          onClick={signIn}
                        >
                          {loginBool ? (
                            <CircularProgress
                              sx={{ color: "#ffff" }}
                              size={27}
                              thickness={6}
                            />
                          ) : (
                            <label>SignIN</label>
                          )}
                        </Button>
                        {/* <Grid container>
                          <Grid item xs={{ justifyContent: "flex-start" }}>
                            <Link href="#" variant="body2">
                              Forgot password?
                            </Link>
                          </Grid>
                        </Grid> */}
                      </Box>
                    </Card>
                  </div>
                </Container>
              </Grid>
            </>
          ) : (
            <>
              <Grid className={styles.loginFormOuterSignup} onClick={() => {}}>
                <Container component="main" maxWidth="sm">
                  <div
                    style={{
                      borderRadius: 10,
                      background:
                        "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,1) 42%, rgba(250,110,252,1) 100%)",
                    }}
                  >
                    <Card
                      style={{
                        padding: "15px 40px",
                        borderRadius: 10,
                        textAlign: "center",
                        marginBottom: 40,
                        backgroundColor: "rgba(255,255,255, 0.9)",
                      }}
                      onClick={() => {
                        if (listData == true) {
                          setListData(false);
                        }
                      }}
                    >
                      <Box>
                        <h1 style={{ color: "rgb(0,119,190)", marginTop: 5 }}>
                          Back-office Signup
                        </h1>
                        <form noValidate>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="outlined"
                                required
                                error={errorSnameMsg ? true : false}
                                fullWidth
                                disabled={pwaVerifyBool ? true : false}
                                id="Sponser Name"
                                label="Sponsor Name"
                                autoFocus
                                name="sponsername"
                                autoComplete={"off"}
                                color="secondary"
                                value={regData?.sname}
                                onChange={(e) => {
                                  setRegData({
                                    ...regData,
                                    sname: e.target.value,
                                  });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {pwaLoaderBool ? (
                                        <CircularProgress
                                          sx={{ color: "rgb(76,25,201)" }}
                                          size={27}
                                          thickness={6}
                                        />
                                      ) : pwaVerifyBool &&
                                        !_.isEmpty(regData.sname) ? (
                                        <CheckCircleSharpIcon color="success" />
                                      ) : isFirstBool && !pwaVerifyBool ? (
                                        <CancelSharpIcon color="error" />
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                                onBlur={() => {
                                  if (isEmpty(regData?.sname)) {
                                    console.log("Sponsor name is required");
                                  } else {
                                    verifyPwaApiCall();
                                  }
                                }}
                                helperText={
                                  errorSnameMsg ? errorSnameMsgTxt : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                error={errorUnameMsg ? true : false}
                                disabled={pwaVerifyBool ? false : true}
                                id="User Name"
                                label="User Name"
                                name="username"
                                autoComplete={"off"}
                                color="secondary"
                                value={regData.uname}
                                onClick={() => {
                                  setListData(false);
                                }}
                                onChange={(e) => {
                                  setErrorUnameMsg(false);
                                  setRegData({
                                    ...regData,
                                    uname: e.target.value,
                                  });
                                }}
                                helperText={
                                  errorUnameMsg
                                    ? "Please enter sponserID"
                                    : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                autoComplete={"off"}
                                name="firstName"
                                variant="outlined"
                                disabled={pwaVerifyBool ? false : true}
                                required
                                error={errorFnameMsg ? true : false}
                                fullWidth
                                id="firstName"
                                label="First Name"
                                color="secondary"
                                value={regData.fname}
                                onClick={() => {
                                  setListData(false);
                                }}
                                onChange={(e) => {
                                  setErrorFnameMsg(false);
                                  setRegData({
                                    ...regData,
                                    fname: e.target.value,
                                  });
                                }}
                                helperText={
                                  errorFnameMsg
                                    ? "Please enter First Name"
                                    : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                error={errorLnameMsg ? true : false}
                                id="lastName"
                                disabled={pwaVerifyBool ? false : true}
                                label="Last Name"
                                name="lastName"
                                autoComplete={"off"}
                                color="secondary"
                                value={regData.lname}
                                onClick={() => {
                                  setListData(false);
                                }}
                                onChange={(e) => {
                                  setErrorLnameMsg(false);
                                  setRegData({
                                    ...regData,
                                    lname: e.target.value,
                                  });
                                }}
                                helperText={
                                  errorLnameMsg
                                    ? "Please enter Last Name"
                                    : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="outlined"
                                required
                                error={errorConMsg ? true : false}
                                disabled={pwaVerifyBool ? false : true}
                                fullWidth
                                name="country"
                                label={"Country"}
                                type="country"
                                autoFocus
                                id="country"
                                // select
                                autoComplete={"off"}
                                color="secondary"
                                // value={regData.country}
                                // value={listValue}
                                onChange={(e) => {
                                  setErrorConMsg(false);
                                  cName = e.target.value;
                                  // console.log("cName ===> ", cName);
                                  filterValue(e.target.value);
                                  setRegData({
                                    ...regData,
                                    country: e.target.value,
                                  });
                                  setFinalBool(true);
                                  setFinalAns(e.target.value);
                                }}
                                value={textColor ? finalAns : regData.country}
                                onClick={() => {
                                  if (pwaVerifyBool) {
                                    setListData(true);
                                  }
                                }}
                                sx={{
                                  color: "black",
                                }}
                                // onChange={(e) => {}}
                                helperText={
                                  errorConMsg ? "Please select country" : null
                                }
                              ></TextField>
                              {listData && pwaVerifyBool ? (
                                <>
                                  <div className={styles.dropDownDiv}>
                                    {_.isArray(arraydata) &&
                                    !_.isEmpty(arraydata)
                                      ? arraydata.map((option, optind) => {
                                          return (
                                            <label
                                              key={`opt${optind}`}
                                              style={{
                                                padding: "3px 5px",
                                                textAlign: "left",
                                                width: "100%",
                                              }}
                                              onClick={() => {
                                                setListValue(
                                                  Object.values(option)
                                                );
                                                setListData(false);
                                                setTextColor(true);
                                                setFinalBool(true);
                                                setFinalAns(
                                                  Object.values(option)
                                                );
                                                setRegData({
                                                  ...regData,
                                                  country:
                                                    Object.keys(
                                                      option
                                                    ).toString(),
                                                });
                                              }}
                                            >
                                              {Object.values(option)}
                                            </label>
                                          );
                                        })
                                      : null}
                                  </div>
                                </>
                              ) : null}

                              {/* <div style={{ background: "green" }}> */}
                              {/* <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Country
                                      </InputLabel>
                                      <Select
                                        // labelId="demo-simple-select-label"
                                        // id="demo-simple-select"
                                        value={currencies}
                                        label="Age"
                                        onChange={handleChange}
                                      >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box> */}
                              {/* </div> */}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="outlined"
                                required
                                error={errorMoNumMsg ? true : false}
                                fullWidth
                                disabled={pwaVerifyBool ? false : true}
                                name="mobileNo"
                                label="Mobile No"
                                id="mobileno"
                                inputProps={{ maxLength: 10 }}
                                autoComplete={"off"}
                                color="secondary"
                                value={regData.mobileno}
                                onClick={() => {
                                  setListData(false);
                                }}
                                onChange={(e) => {
                                  setErrorMoNumMsg(false);
                                  const tempVal = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );

                                  setRegData({
                                    ...regData,
                                    mobileno: tempVal,
                                  });
                                }}
                                helperText={
                                  errorMoNumMsg ? errorMoNumMsgTxt : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                error={errorEmailMsg ? true : false}
                                id="email"
                                disabled={pwaVerifyBool ? false : true}
                                label="Email Address"
                                name="email"
                                autoComplete={"off"}
                                color="secondary"
                                value={regData.email}
                                onClick={() => {
                                  setListData(false);
                                }}
                                onChange={(e) => {
                                  setErrorEmailMsg(false);
                                  setRegData({
                                    ...regData,
                                    email: e.target.value,
                                  });
                                }}
                                helperText={
                                  errorEmailMsg ? errorEmailMsgTxt : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                required
                                error={errorPassMsg ? true : false}
                                fullWidth
                                disabled={pwaVerifyBool ? false : true}
                                name="password"
                                label="Password"
                                type={values.showPassword ? "text" : "password"}
                                id="password"
                                autoComplete={"off"}
                                color="secondary"
                                value={regData.password}
                                onClick={() => {
                                  setListData(false);
                                }}
                                onChange={(e) => {
                                  setErrorPassMsg(false);
                                  setRegData({
                                    ...regData,
                                    password: e.target.value,
                                  });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {values.showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={
                                  errorPassMsg ? errorPassMsgTxt : null
                                }
                              />
                            </Grid>

                            {/* <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value="allowExtraEmails"
                                    color="primary"
                                  />
                                }
                                label=" marketing promotions  updates via email."
                              />
                            </Grid> */}
                          </Grid>
                          <Button
                            type="submit"
                            fullWidth
                            disabled={signupBool ? false : true}
                            variant="contained"
                            sx={{
                              borderRadius: 10,
                              elivation: 10,
                              marginTop: 2,
                              padding: 2,
                              fontSize: "1rem",
                              color: "#ffff",
                              fontWeight: "bold",
                              background:
                                "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,1) 42%, rgba(250,110,252,1) 100%)",
                            }}
                            onClick={signup}
                          >
                            {regBool ? (
                              <CircularProgress
                                sx={{ color: "#ffff" }}
                                size={27}
                                thickness={6}
                              />
                            ) : (
                              <label>SignUp</label>
                            )}
                          </Button>
                          {/* <Grid container justify="flex-end">
                            <Grid item>
                              <Link href="#" variant="body2">
                                Already have an account? Sign in
                              </Link>
                            </Grid>
                          </Grid> */}
                        </form>
                      </Box>
                    </Card>
                  </div>
                </Container>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
