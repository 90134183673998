/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import { json, Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import useStyles from "./styles";

const Notification = (props) => {
  const { images = "", title = "", time = "", message = "",type="",link } = props;
  const location = useLocation();
  const styles = useStyles();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  return (
    <Grid
      container
      lg={12}
      style={{
        backgroundImage:
          'url("https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png")',
        // height: "100vh",
        // width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Grid
        container
        lg={12}
        style={{
          // backgroundColor: "#1134",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          // width: "100%",
          // height: "75vh",
          padding:10,
        }}
      >
        <Grid lg={3} md={3} sm={3}></Grid>

        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ padding: 8, borderRadius: 8, backgroundColor: "#d9d9d9",overflow:"auto" }}
        >
          <Card className={styles.detailsPage}>
            {/* <img
              src={images}
              alt="notificationImg"
              style={{
                width: "100%",
                height: "40vh",
                backgroundSize: "containt",
              }}
            /> */}
            <div style={{ paddingInlineStart: 30 ,padding: 10 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    textTransform: "capitalize",
                  }}
                >
                  {title}
                </h2>
                <h5 style={{ marginTop: 1,textAlign:"right", marginBottom: 0 }}>Time : {time}</h5>
              </div>
              {
                link ?
                <p><a href={message} target="_blank" >{message}</a></p>
                :
              <p style={{ wordSpacing: 5 }}>{message}</p>
              }
              <Button
                type="submit"
                variant={show ? "contained" : "contained"}
                sx={{
                  borderRadius: 2,
                  elivation: 10,
                  width: 150,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 15,
                  fontWeight: "bold",
                  background:
                    "linear-gradient(90deg, rgb(76, 25, 201) 30%, rgba(250,110,252,1) 90%)",
                }}
                style={{
                  letterSpacing: 0,
                  padding: "5px 10px",
                  width: 120,
                  lineHeight: 0,
                  textTransform: "none",
                }}
                onClick={() => {
                  setShow(true);
                  navigate("/");
                }}
              >
                <KeyboardDoubleArrowLeftIcon fontSize="large" /> BackPage
              </Button>
            </div>
          </Card>
        </Grid>

        <Grid lg={3} md={3} sm={3}></Grid>
      </Grid>
    </Grid>
  );
};

export default Notification;
