const actions = {
  SET_USER_DATA: "auth/SET_USER_DATA",
  SET_DROPDOWN_DATA: "auth/SET_DROPDOWN_DATA",
  SET_DISPLAY_NOTIFICATION_POP_UP: "auth/SET_DISPLAY_NOTIFICATION_POP_UP",
  SET_REGPARAMS_DATA: "auth/SET_REGPARAMS_DATA",
  SET_MAIN_TOKEN: "auth/SET_MAIN_TOKEN",
  SET_NOTIFICATION_DATA: "auth/SET_NOTIFICATION_DATA",

  displayNotificationPopUp: (isNotifiy) => (dispatch) =>
    dispatch({
      type: actions.SET_DISPLAY_NOTIFICATION_POP_UP,
      isNotifiy,
    }),

  setUserData: (userdata) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userdata,
    }),

  setRegParamsData: (registerParamas) => (dispatch) =>
    dispatch({
      type: actions.SET_REGPARAMS_DATA,
      registerParamas,
    }),

  setDropDownData: (dropdowndata) => (dispatch) =>
    dispatch({
      type: actions.SET_DROPDOWN_DATA,
      dropdowndata,
    }),

  setMainToken: (mainToken) => (dispatch) =>
    dispatch({
      type: actions.SET_MAIN_TOKEN,
      mainToken,
    }),

  setNotificationData: (notificationData) => (dispatch) =>
    dispatch({
      type: actions.SET_NOTIFICATION_DATA,
      notificationData,
    }),
};

export default actions;
