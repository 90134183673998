import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import { json, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useStyles from "./styles";
import { Setting } from "../../utils/Setting";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAPIProgressData } from "../../utils/APIHelper";
import CircularProgress from "@mui/material/CircularProgress";

const LogOut = (props) => {
  const styles = useStyles();
  const location = useLocation();
  const { useruuid, registerParamas, mainToken } = useSelector((state) => state.auth);
  const { setMainToken } = authActions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutBool, setLogoutBool] = useState(false);
  const [cancleBool, setCancleBool] = useState(false);

  // logout api call

  async function logoutApiCall() {
    setLogoutBool(true);
    const Data = {};
    const header = {
      Authorization: "5e9f01a0-78aa-48b3-ad06-06dd53a029a8",
      Token: mainToken ? mainToken : '',
    };

    try {
      let endPoint = Setting.endpoints.logout;
      const response = await getAPIProgressData(endPoint, "POST", {}, header, true);
      if (response?.success === 1) {
        dispatch(setMainToken(''));
        localStorage.setItem("pwaAccessToken", JSON.stringify(''));
        localStorage.setItem("pwaUserDetails", JSON.stringify({}));
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          closeButton: false,
        });
        setTimeout(() => {
        setLogoutBool(false);
          navigate("/Login");
          window.location.reload(false);
        }, 1500);
      } else {
        setLogoutBool(false);
        navigate("/Logout");
        setInterval(() => {
          window.location.reload(false);
        }, 5000);
      }
    } catch (err) {
      setLogoutBool(false);
      console.log("Catch Part-------Logout---", err);
    }
  }

  return (
    <Grid
      container
      lg={12}
      style={{
        backgroundImage:
          'url("https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png")',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Grid container lg={12} className={styles.main}>
        <Grid item lg={3} style={{}}>
          <Card className={styles.cardDiv}>
            <h2 style={{ marginTop: 0 }}>Are you sure want to logout?</h2>
            <div className={styles.divv}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  elivation: 10,
                  padding: 1,
                  width: 150,
                  fontSize: "0.8rem",
                  margin: 1,
                  fontWeight: "bold",
                  background:
                    "linear-gradient(90deg, rgb(76, 25, 201) 10%, rgba(75,63,207,1) 42%, rgba(250,110,252,1) 100%)",
                }}
                onClick={() => {
                  logoutApiCall();

                  // localStorage.setItem("pwaUserDetails", JSON.stringify({}));
                  // toast.success("Thank You for visit.", {
                  //   position: toast.POSITION.TOP_RIGHT,
                  //   autoClose: 5000,
                  //   closeButton: false,
                  // });
                  // navigate("/Login");
                  // window.location.reload(false);
                }}
              >
                {logoutBool ? (
                  <CircularProgress
                    sx={{ color: "#ffff" }}
                    size={27}
                    thickness={6}
                  />
                ) : (
                  <label>Log Out</label>
                )}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                sx={{
                  elivation: 10,
                  width: 150,
                  padding: 1,
                  border: "1px solid rgb(76, 25, 201)",
                  // marginLeft: 2,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  margin: 1,
                }}
                onClick={() => {
                  setCancleBool(true);
                  setTimeout(()=>{
                    navigate("/");
                  window.location.reload(false);
                  },1000)
                }}
              >
                {cancleBool ? (
                  <CircularProgress
                    sx={{ color: "rgb(76,25,201)" }}
                    size={27}
                    thickness={6}
                  />
                ) : (
                  <label>Cancel</label>
                )}
              </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogOut;
