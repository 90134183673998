/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import { json, Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import useStyles from "./styles";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import CImageCard from "../../Components/CImageCard";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../Redux/reducers/auth/actions";
import {
  getApiData,
  getApiDataProgress,
  getAPIProgressData,
} from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import moment from "moment";

const Notification = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { notificationData } = useSelector((state) => state.auth);
  const { useruuid, registerParamas, mainToken } = useSelector(
    (state) => state.auth
  );
  const { setNotificationData } = authActions;
  const [show, setShow] = useState(true);
  const [read, setRead] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [data, setData] = useState("");
  const [notiArr, setNotiArr] = useState(notificationData);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const uname = JSON.parse(localStorage.getItem("pwaUserDetails"));
  const NotiData = JSON.parse(localStorage.getItem("notificationData"));

  React.useEffect(() => {
    notificationApiCall();
  }, []);

  // notification APi CAll
  async function notificationApiCall() {
    const loginData = {};
    const header = {
      Authorization: "5e9f01a0-78aa-48b3-ad06-06dd53a029a8",
      Token: mainToken ? mainToken : "",
    };

    try {
      let endPoint = Setting.endpoints.notificationHistory;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        {},
        header,
        true
      );
      if (response?.success === 1) {
        console.log("response =success==> ", response?.message);
        setNotificationDetails(response?.message);
        localStorage.setItem(
          "notificationData",
          JSON.stringify(response?.message)
        );
        // toast.success(response.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 2000,
        //   closeButton: false,
        // });
      } else {
        console.log("response ===> ", response?.message);
        toast.warning(response.error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          closeButton: false,
        });
      }
    } catch (err) {
      console.log("err ===> ", err);
      // setLoginBool(false);
      // console.log("Catch Part", err);
      toast.error("Warning Error", err, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 2000,
        closeButton: false,
      });
    }
  }

  const checkRead = (item) => {
    let dummArray = notificationData;
    dummArray.map((item2, ind2) => {
      if (item2.id === item?.id) {
        item2.isRead = true;
      }
    });
    localStorage.setItem(
      "notificationLocalStoreArray",
      JSON.stringify(dummArray)
    );

    dispatch(setNotificationData(dummArray));
  };

  return (
    <Grid
      container
      lg={12}
      style={{
        backgroundImage:
          'url("https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png")',
        // height: "100vh",
        // width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className={styles.circle}></div>
      <div className={styles.circle1}></div>
      <div className={styles.circle2}></div>
      <div className={styles.circle3}></div>

      <Grid
        container
        lg={12}
        style={{
          // backgroundColor: "#1134",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          // width: "100%",
          // height: "100%",
          padding: 20,
        }}
      >
        <Grid lg={3} md={2} sm={2}></Grid>

        <Grid
          lg={6}
          md={8}
          sm={8}
          xs={12}
          style={{
            background:
              "linear-gradient(90deg, rgba(76, 25, 201,0.4) 30%, rgba(250,110,252,0.3) 90%)",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <Card
            className={styles.detailsPage1}
            style={{ backgroundColor: "#fff" }}
          >
            <div className={styles.titleDiv}>
              <h3 className={styles.nTitle}>All Notification</h3>
            </div>
            <div className={styles.responsive}>
              {_.isArray(notificationDetails) && !_.isEmpty(notificationDetails)
                ? notificationDetails?.map((item, ind) => {
                    const dd = new Date(item?.sent_on * 1000);
                    const ddDate = moment(dd).format("DD MMM YYYY h:mm:ss A");
                    return (
                      <div
                        className={styles.cardDiv}
                        key={`notif${ind}`}
                        onClick={() => {
                          setOpenModal(!openModal);
                          checkRead(item, ind);
                          <>
                            {item?.type === "newsletter" ? (
                              <a href={item?.body} target="_blank" />
                            ) : (
                              navigate("/FirstPage", {
                                state: {
                                  title: item?.title,
                                  id: item?.id,
                                  message: item?.body,
                                  image: item?.img,
                                  time: ddDate,
                                  type: item?.type,
                                },
                              })
                            )}
                          </>;
                        }}
                      >
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                              {(uname?.name).slice(0, 2)}
                            </Avatar>
                          }
                          // action={
                          //   <IconButton
                          //     aria-label="settings"
                          //     onClick={() => {
                          //       toast.success("Coming Soon....!", {
                          //         position: toast.POSITION.TOP_RIGHT,
                          //       });
                          //     }}
                          //     style={{ color: "#000" }}
                          //   >
                          //     <MoreVertIcon />
                          //   </IconButton>
                          // }
                          title={item?.title}
                          subheader={ddDate}
                          titleTypographyProps={{
                            color: "#000",
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                          subheaderTypographyProps={{
                            color: "#000",
                            fontSize: 12,
                          }}
                        />
                        {/* <img
                      width="100%"
                      height="250"
                      src={item?.img}
                      alt="Beyond Infinity"
                    /> */}
                        {item?.type == "newsletter" ? (
                          <p
                            style={{
                              paddingInlineStart: 20,
                            }}
                          >
                            <a href={item?.body}>{item?.body}</a>
                            <label
                              style={{ color: "blue", marginLeft: 10 }}
                              onMouseEnter={() => {
                                setData(item?.id);
                                setRead(true);
                                // checkRead(item,ind);
                                {
                                  item?.id === data && setRead(!read);
                                }
                              }}
                            >
                              {item?.type == "newsletter"
                                ? false
                                : read && item?.id == data
                                ? "Read Less..."
                                : "Read More..."}
                            </label>
                          </p>
                        ) : (
                          <p
                            style={{
                              paddingInlineStart: 20,
                            }}
                          >
                            {(item?.body).substring(
                              0,
                              read && item?.id == data ? 500 : 100
                            )}
                            <label
                              style={{ color: "blue", marginLeft: 10 }}
                              onMouseEnter={() => {
                                setData(item?.id);
                                setRead(true);
                                // checkRead(item,ind);
                                {
                                  item?.id === data && setRead(!read);
                                }
                              }}
                            >
                              {read && item?.id == data
                                ? "Read Less..."
                                : "Read More..."}
                            </label>
                          </p>
                        )}
                      </div>
                    );
                  })
                : null}
            </div>
          </Card>
        </Grid>

        <Grid lg={3} md={2} sm={2}></Grid>
      </Grid>
    </Grid>
  );
};

export default Notification;
