import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  homePage: {
    justifyContent: "center",
    backgroundColor: "black",
    display: "flex",
  },
  detailsPage: {
    borderRadius:20,
    elivation: 50,
    boxShadow:
      " rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
  },

  detailsPage1: {
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    elivation: 10,
    padding:5,
    // padding: "20px 0px",
    // backgroundColor:"grey"
  },
  cardDiv: {
    border: "1px solid rgba(76, 25, 201,0.3)",
    background: "linear-gradient(90deg, rgba(76, 25, 201,0.2) 30%, rgba(250,110,252,0.2) 90%)",
    marginBottom: 25,
    borderRadius: 10,
    // padding: "10px 0",
    // boxShadow:" rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    // boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
  },
  responsive: {
    overflow: "scroll",
    height: 600,
    width: "90%",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&::-Webkit-Scrollbar": { display: null, width: 0, height: 0 },
    "&:-WebkitScrollbar": { display: null, width: 0, height: 0 },
  },
  titleDiv: {
    // margin: 0,
    // margin:"0px 10px",
    textAlign:"center",
    // paddingBottom:10,
    // backgroundColor:'red',
    padding:10
  },
  nTitle:{
    margin:0,
    paddingVertical:20,
    color:"rgba(76, 25, 201,0.9)"
    // backgroundColor:'pink'
  }
}));

export default useStyles;
