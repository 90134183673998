import { Grid } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import { useLocation } from "react-router-dom";

const ImageWindow = () => {
  const styles = useStyles();
  const location = useLocation();


  return (
    <Grid container lg={12} className={styles.homepage}>
      <iframe
        src="https://www.brafton.com/blog/email-marketing/cost-of-email-marketing/"
        style={{border:"3px solid red",width:"100%", visibility: 'visible'}}
      ></iframe>
    </Grid>
  );
};

export default ImageWindow;
