import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  homePage: {
    justifyContent: "center",
    backgroundColor: "black",
    display: "flex",
  },
  main: {
    textAlign: "center",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding:50,
  },
  cardDiv:{
    borderRadius: 10,
    padding: "30px 10px",
    display: "block",
    margin: 10,
    justifyContent: "center",
    alignItems: "center",
    // border:"1px solid red"
  },
  divv:{
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "@media(max-width:320px)": {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export default useStyles;
