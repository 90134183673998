export const notificationStaticArray = [
  {
    id: 1,
    title: "Hello",
    message:
      "As you can see the should be some space above, below, and to the right of the image. The text should not be creeping on the image. Creeping is just not right. Images need breathing room too. Let them speak like you words. Let them do their jobs without any hassle from the text. In about one more sentence here, we’ll see that the text moves from the right of the image down below the image in seamless transition. Again, letting the do it’s thang. Mission accomplished!",
    img: "https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png",
    isRead: false,
    time: "09:33 AM",
  },
  {
    id: 2,
    title: "Welcome",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: "https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png",
    isRead: false,
    time: "10:33 PM",
  },
  {
    id: 3,
    title: "morning",
    message:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    img: "https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png",
    isRead: false,
    time: "04:00 AM",
  },
  {
    id: 4,
    title: "hey",
    message: "how are you",
    img: "https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png",
    isRead: false,
    time: "06:33 AM",
  },
  {
    id: 5,
    title: "hey guess",
    message:
      "Welcome to image alignment! The best way to demonstrate the ebb and flow of the various image positioning options is to nestle them snuggly among an ocean of words. Grab a paddle and let’s get started. ?",
    img: "https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png",
    isRead: false,
    time: "05:00 PM",
  },
  {
    id: 6,
    title: "let's go",
    message:
      "In just a bit here, you should see the text start to wrap below the right aligned image and settle in nicely. There should still be plenty of room and everything should be sitting pretty. Yeah… Just like that. It never felt so good to be right",
    img: "https://beyondinfinity.club/site/assets/images/beyond-infinity-logo-1596x1197.png",
    isRead: false,
    time: "04:33 PM",
  },
];
