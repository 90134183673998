import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  homePage: {
    backgroundColor: "black",
    width:"100%",
    height:"100%",
  },
  footerText: {
    display: "flex",
    justifyContent: "center",
    // border: "2px solid red",
    alignItems: "center",
    flexWrap: "no-wrap",
    "@media(max-width: 350px)": {
      justifyContent: "center",
      display: "flex",
    },
    "@media(max-width: 1000px)": {
      paddingLeft: "0%",
      justifyContent: "center",
    },
  },
  footerText1: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "5%",
    alignItems: "center",
    flexWrap: "wrap",
    // border:"1px solid green",
    "@media(max-width: 600px)": {
      paddingLeft: "0%",
      justifyContent: "center",
      flexWrap:"wrap",
      display:"flex",
      textAlign:"center",
      alignItems:"center",
      gap:1,
    },
  },
  navBar: {
    textDecoration: "none",
    fontSize: "1.2rem",
    color: "#fa6efc",
    "@media(max-width: 900px)": {
      fontSize:"20px",
    },
    "@media(max-width: 500px)": {
      fontSize:"15px",
    }
  },
  copyLine: {
    fontSize: "20px",
    margin:0,
    color: "#ffff",
    "@media(max-width:1200px)": {
      fontSize:"15px",
    },
    "@media(max-width: 550px)": {
      fontSize:"15px",
    },
    "&:hover ": {
      color: "#00ace0",
    },
  },
  liList: {
    listStyle: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 600px)": {
      margin:1,
      justifyContent: "center",
    },
  },
}));

export default useStyles;
